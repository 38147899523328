import React from 'react'
import loadable from '@loadable/component'

const HubspotAssessment = loadable(() => import('../common/hubspot-assessment'))
const LiteYoutubeEmbedHere = loadable(() => import('../common/lite-youtube-embed-here'))

const PageHostedExchangeSection = () => (
  <>
    <div className="xl:hidden w-full xl:w-1/2 mx-auto">
      <HubspotAssessment />
    </div>

    <div className="bg-white w-full border-b">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>Microsoft Exchange Server</h2>
          <p className='text-lg text-gray-800 mb-4'>There are a lot of questions that need to be addressed when considering moving to the cloud:</p>
          <ul className='list-inside list-disc mb-6 ml-3'>
            <li className='text-lg text-gray-800 mb-4'>Exchange on Premises Server?</li>
            <li className='text-lg text-gray-800 mb-4'>Is hosting an exchange server at your site the best solution or is using a 3rd party such as Office 365 or Appriver a more cost-effective solution?</li>
            <li className='text-lg text-gray-800 mb-4'>Are they HIPPA compliant?</li>
            <li className='text-lg text-gray-800 mb-4'>What security protocols are available?</li>
            <li className='text-lg text-gray-800 mb-4'>How does email encryption work?</li>
          </ul>
          <p className='text-lg text-gray-800'>Our team can help from choosing a provider, addressing security questions and making it a smooth transition.</p>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <LiteYoutubeEmbedHere youtubeID="27EPMkYvtss" />
        </div>
      </div>
    </div>
  </>
)

export default PageHostedExchangeSection