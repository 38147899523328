import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import BenefitsSection from "../components/sections/benefits-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"
import CtaSection from "../components/sections/cta-section"
import PageHostedExchangeHeroSection from "../components/sections/page-hosted-exchange-hero-section"
import PageHostedExchangeSection from "../components/sections/page-hosted-exchange-section"

const HostedExchangePage = () => (
  <Layout>
    <Seo
      title='Hosted Exchange / Microsoft Exchange Services Orange County'
      description='Microsoft Exchange is an email collaboration platform that can be accessed on all of your devices. Giving you the opportunity to securely manage your inner company communications and remotely manage mobile devices.'
    />

    <PageHostedExchangeHeroSection />
    <PageHostedExchangeSection />
    <BenefitsSection />
    <CapabilitiesSection />
    <QuickQuoteSection />
    <CtaSection />

    <div className="bg-white w-full pt-16 pb-20">
      <h2 className='text-2xl md:text-3xl text-blue-900 text-center font-bold mb-8'>Related IT Services</h2>

      <div className='w-11/12 max-w-screen-lg grid grid-cols-1 md:grid-cols-2 gap-8 mx-auto'>
        <div>
          <Link to="/secure-remote-access">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Secure Remote Access
            </button>
          </Link>
        </div>
        <div>
          <Link to="/managed-it-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Fully Managed IT Solutions
            </button>
          </Link>
        </div>
        <div>
          <Link to="/server-workstation-setup-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Infrastructure Setup and Maintenance
            </button>
          </Link>
        </div>
        <div>
          <Link to="/firewall-router-setup-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              IT Security / Cyber Security
            </button>
          </Link>
        </div>
        <div>
          <Link to="/cloud-backup-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              Online Cloud Backup
            </button>
          </Link>
        </div>
        <div>
          <Link to="/voip-design-implementation-services">
            <button className="w-full bg-site-lightblue hover:bg-blue-700 text-white p-4 text-center text-lg font-medium rounded shadow-lg">
              VOIP Design and Implementation
            </button>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default HostedExchangePage
